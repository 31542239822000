'use strict';

Gri.module({
  name: 'timeline-slider',
  ieVersion: null,
  $el: $('.timeline-slider'),
  container: '.timeline-slider',
  fn: function () {
    tofas.timelineSlider = {
      el: $('.timeline-slider'),
      content: $('.timeline-slider .slider-content'),
      itemTag: $('.timeline-slider .slider-content .item'),
      timelineTag: $('.timeline-slider .slider-timeline .timeline-content span'),
      timelineBar: $('.timeline-slider .slider-timeline .timeline-content .timeline-bar'),
      init: function () {
        tofas.timelineSlider.slider();
        //tofas.timelineSlider.button();
        tofas.timelineSlider.timeline.init();
        tofas.timelineSlider.detailButton();
        //tofas.timelineSlider.video.init();
        $(window).on('resize', function () {
          tofas.timelineSlider.overscrollReset();
        });
      },
      overscroll: function () {
        if (window.matchMedia('(min-width: 991px)').matches) {
          $('[data-custom-scroll]').each(function () { 
            $(this).overscroll({
              direction: 'horizontal',
              ignoreSizing: true
            }).on('overscroll:dragstart', function () { console.log('Drag started!') });
          });
        }
      },
      overscrollReset: function () {
        $('[data-custom-scroll]').each(function () {
          $(this).removeOverscroll();
        });
        tofas.timelineSlider.overscroll();
      },
      slider: function () {
        tofas.timelineSlider.content.on('init', function (event, slick) {
          tofas.timelineSlider.overscroll();
          tofas.timelineSlider.fancyboxInit();
        });

        tofas.timelineSlider.content.slick({
          autoplay: false,
          autoplaySpeed: 10000,
          speed: 600,
          slidesToShow: 1,
          draggable: false,
          slidesToScroll: 1,
          pauseOnHover: false,
          arrows: true,
          fade: false,
          dots: false,
          adaptiveHeight: false,
          infinite: false,
          prevArrow: '<button type="button" class="slick-prev"></button>',
          nextArrow: '<button type="button" class="slick-next"></button>',
          responsive: [
            {
              breakpoint: 992,
              settings: {
                adaptiveHeight: true
              }
            }            
          ]
        });
        tofas.timelineSlider.content.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          tofas.timelineSlider.timeline.change(nextSlide);
          tofas.timelineSlider.timeline.active(nextSlide);
          tofas.timelineSlider.fancyboxInit();
        });

      },
      button: function () {
        $('[data-video-scroll]').on('click', function () {
          $('html, body').animate({ scrollTop: ($('[data-video-content]').offset().top - $('.header-alpha').height()) }, 500);
        });
      },
      timeline: {
        init: function () {
          tofas.timelineSlider.timeline.breakpoint();
          tofas.timelineSlider.timeline.default();
          tofas.timelineSlider.timeline.goTo();
        },
        count: function () {
          var item = tofas.timelineSlider.content.find('.item').length + 1;
          return item;
        },
        change: function (activeSlide) {
          var calc = (100 / tofas.timelineSlider.timeline.count()) * (activeSlide + 1);
          tofas.timelineSlider.timelineBar.css({ "width": calc + "%" });
        },
        active: function (activeSlide) {
          var i = 0;
          tofas.timelineSlider.timelineTag.removeClass('active');
          tofas.timelineSlider.timelineTag.eq(activeSlide).addClass('active');
          $.each(tofas.timelineSlider.timelineTag, function () {
            if (activeSlide > i) {
              tofas.timelineSlider.timelineTag.eq(i).addClass('select');
            } else {
              tofas.timelineSlider.timelineTag.eq(i).removeClass('select');
            }
            i++;
          });
        },
        default: function () {
          var firstElement = (100 / tofas.timelineSlider.timeline.count());
          tofas.timelineSlider.timelineBar.css({ "width": firstElement + "%" });
          tofas.timelineSlider.timelineTag.first().addClass('active');
        },
        breakpoint: function () {
          var calc = 100 / tofas.timelineSlider.timeline.count();
          var i = 1;
          $.each(tofas.timelineSlider.timelineTag, function () {
            $(this).css({ "left": (calc * i) + "%" });
            i++;
          });
        },
        goTo: function () {
          $('span[data-slide]').click(function () {
            var slide = $(this).data('slide');
            tofas.timelineSlider.content.slick('slickGoTo', slide);
          });
        }
      },
      video: {
        init: function () {
          tofas.timelineSlider.video.open();
          tofas.timelineSlider.video.close();
        },
        open: function () {
          $('.timeline-slider .play').on('click', function () {
            var video = $(this).attr("data-video");
            if (video != "") {
              $('.timeline-slider .slider-video').addClass('video-opened');
              $('.timeline-slider .slider-video').append("<video loop autoplay poster='/images/slider-1.png' class='fullscreen'> <source src='/images/video/" + video + ".webm' type='video/webm'><source src='/images/video/" + video + ".mp4' type='video/mp4'></video>");
            } else {
              //consle.log("video URL!");
            }
          });
        },
        close: function () {
          $('.timeline-slider .slider-video').on('click', function () {
            $(this).find('video')[0].pause();
            $(this).removeClass('video-opened');
            $(this).html('');
          });
        }
      },
      fancyboxInit: function() {
        if($('[data-fancybox]').length > 0){
          $('[data-fancybox]').fancybox({
            infobar : false,
            buttons : false,
            afterLoad : function( instance, current ) {
              if ( instance.group.length > 1 && current.$content ) {
                current.$content.append('<a data-fancybox-next class="button-next" href="javascript:;">→</a><a data-fancybox-previous class="button-previous" href="javascript:;">←</a>');
              }
            }
          });
        }
      }
    }

    /**/

    tofas.timelineSlider.init();


    

  }
});


